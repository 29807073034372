<template>
<div class="tab-reviews">
    <h3 class="tab-title">Student Reviews:</h3>
    <div class="reviews-wrapper reviews-active">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <SingleReview v-for="review in reviews" :key="review" :review="review" />
            </div>
            <!-- Add Pagination -->
            <div class="swiper-pagination"></div>
        </div>
    </div>
    <div class="reviews-btn">
        <button type="button" class="btn btn-primary btn-hover-dark" data-bs-toggle="modal" data-bs-target="#reviewsModal">Write A Review</button>
    </div>

    <!-- Reviews Form Modal Start -->
    <div class="modal fade" id="reviewsModal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add a Review</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <!-- Reviews Form Start -->
                <div class="modal-body reviews-form">
                    <form action="#">
                        <div class="row">
                            <div class="col-md-6">
                                <!-- Single Form Start -->
                                <div class="single-form">
                                    <input type="text" placeholder="Enter your name">
                                </div>
                                <!-- Single Form End -->
                            </div>
                            <div class="col-md-6">
                                <!-- Single Form Start -->
                                <div class="single-form">
                                    <input type="text" placeholder="Enter your Email">
                                </div>
                                <!-- Single Form End -->
                            </div>
                            <div class="col-md-12">
                                <!-- Single Form Start -->
                                <div class="reviews-rating">
                                    <label>Rating</label>
                                    <ul id="rating" class="rating">
                                        <li class="star" title='Poor' data-value='1'><i class="icofont-star"></i></li>
                                        <li class="star" title='Poor' data-value='2'><i class="icofont-star"></i></li>
                                        <li class="star" title='Poor' data-value='3'><i class="icofont-star"></i></li>
                                        <li class="star" title='Poor' data-value='4'><i class="icofont-star"></i></li>
                                        <li class="star" title='Poor' data-value='5'><i class="icofont-star"></i></li>
                                    </ul>
                                </div>
                                <!-- Single Form End -->
                            </div>
                            <div class="col-md-12">
                                <!-- Single Form Start -->
                                <div class="single-form">
                                    <textarea placeholder="Write your comments here"></textarea>
                                </div>
                                <!-- Single Form End -->
                            </div>
                            <div class="col-md-12">
                                <!-- Single Form Start -->
                                <div class="single-form">
                                    <button class="btn btn-primary btn-hover-dark">Submit Review</button>
                                </div>
                                <!-- Single Form End -->
                            </div>
                        </div>
                    </form>
                </div>
                <!-- Reviews Form End -->
            </div>
        </div>
    </div>
    <!-- Reviews Form Modal End -->
</div>
<!-- Tab Reviews End -->
</template>
<script>
import SingleReview from '@/components/CourseDetails/SingleReview.vue'
import Swiper from '@/assets/js/plugins/swiper-bundle.min.js'
export default{
    name:"Review",
    props:['reviews'],
    components:{
        SingleReview
    },
    mounted(){
       
        /*--
        Reviews
        -----------------------------------*/
        var edule = new Swiper('.reviews-active .swiper-container', {
            speed: 600,
            spaceBetween: 30,
            loop: true,  
            pagination: {
                el: '.reviews-active .swiper-pagination',
                clickable: true,
            },      
            autoplay: {
                delay: 8000,
            },
        });

    }
}
</script>