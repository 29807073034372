<template>
<!-- Single Reviews Start -->
<div class="single-review swiper-slide">
    <div class="review-author">
        <div class="author-thumb">
            <img :src="review.image" alt="Author">
            <i class="icofont-quote-left"></i>
        </div>
        <div class="author-content">
            <h4 class="name">{{ review.name }}</h4>
            <span class="designation">{{ review.certificate }}, NG</span>
            <span class="rating-star">
                    <span class="rating-bar" style="width: 100%;"></span>
            </span>
        </div>
    </div>
    <p>{{ review.story }}</p>
</div>
<!-- Single Reviews End -->
</template>'
<script>
export default{
    name:"SingleReview",
    props:['review']
}
</script>