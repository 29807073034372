<template>
<div class="col-lg-4">
    <!-- Courses Details Sidebar Start -->
    <div class="sidebar">

        <!-- Sidebar Widget Information Start -->
        <div class="sidebar-widget widget-information">
            <div class="info-price">
                <span class="price">₦ {{ getCourse.price }}</span>
            </div>
            <div class="info-list">
                <ul>
                    <li><i class="icofont-man-in-glasses"></i> <strong>Instructor</strong> <span>{{ getCourse.authorName }}</span></li>
                    <li><i class="icofont-clock-time"></i> <strong>Duration</strong> <span>{{ getCourse.time }}</span></li>
                    <li><i class="icofont-book-alt"></i> <strong>Lectures</strong> <span>{{ getCourse.lectures }}</span></li>
                    <!-- <li><i class="icofont-bars"></i> <strong>Level</strong> <span>{{ getCourse.level }}</span></li> -->
                    <li><i class="icofont-book-alt"></i> <strong>Language</strong> <span>English</span></li>
                    <li><i class="icofont-certificate-alt-1"></i> <strong>Certificate</strong> <span>{{ getCourse.certificate }}</span></li>
                </ul>
            </div>
            <div class="info-btn">
                <a href="#" class="btn btn-primary btn-hover-dark">Enroll Now</a> 
                <!-- <li><router-link :class="{'text-dark': this.$route.name != 'Home'}"  :to="{ name:'Contact'}">Contact</router-link></li> -->
            </div>
        </div>
        <!-- Sidebar Widget Information End -->

        <!-- Sidebar Widget Share Start -->
        <div class="sidebar-widget">
            <h4 class="widget-title">Share Course:</h4>

            <ul class="social">
                <li><a href="https://facebook.com/bhubcollege"><i class="flaticon-facebook"></i></a></li>
                <li><a href="https://linkedin.com/bhubcollege"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="https://twitter.com/bhubcollege"><i class="flaticon-twitter"></i></a></li>
                <li><a href="https://instagram.com/bhubcollege"><i class="flaticon-instagram"></i></a></li>
            </ul>
        </div>
        <!-- Sidebar Widget Share End -->

    </div>
    <!-- Courses Details Sidebar End -->
</div>
</template>
<script>
export default{
    name:"CourseSidebar",
    props:['getCourse']
}
</script>